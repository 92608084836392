#home-page {

	.banner-section {
		position: relative;
		width: 100%;

		.swiper {
			.swiper-slide {
				.box {
					display: block;

					img {
						width: 100%;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next {
				width: 74px;
				height: 74px;
				color: $blue;
				border-radius: 50%;
				background-color: #ffffff;
				@include transition(150ms);
				opacity: 1;

				&:after {
					font-family: "Font Awesome 5 Pro";
					font-weight: 300;
					font-size: 2.4rem;
				}

				&:hover {
					background-color: rgba(255,255,255,0.85);
				}
			}

			.swiper-button-prev {
				left: 20px;

				&:after {
					content: "\f060";
				}
			}

			.swiper-button-next {
				right: 20px;

				&:after {
					content: "\f061";
				}
			}
		}

		.desktop {}
		.mobile {
			display: none;
		}

		@media screen and (max-width: 767px) {
			.desktop {
				display: none;
			}
			.mobile {
				display: block;
			}

			.swiper {
				.swiper-button-prev, .swiper-button-next {
					width: 52px;
					height: 52px;

					&:after {
						font-size: 1.55rem;
					}
				}

				.swiper-button-prev {
					left: 14px;
				}
				.swiper-button-next {
					right: 14px;
				}
			}
		}
	}

	.products-section {
		padding-top: 35px;
		padding-bottom: 35px;

		.title-s1 {
			margin-bottom: 0.25rem;
		}

		.swiper {
			.swiper-slide {
				padding-top: 5px;
				padding-bottom: 5px;
			}

			.swiper-button-prev, .swiper-button-next {
				top: 40%;
				color: $orange;
				font-weight: 700;

				&:after {
					font-size: 2.1rem;
				}
			}
		}

		@media screen and (max-width: 575px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	.how-section {
		padding-top: 35px;
		padding-bottom: 35px;

		.title-s1 {
			margin-bottom: 1.9rem;
		}

		.col-info {
			padding: 0;
			height: 98vh;
			min-height: 520px;

			.box {
				@include flex-center-xy();
				padding: 20px 15px;
				flex: 0 0 100%;
				height: 100%;
				align-items: center;
				background-color: $blue;

				.inside {
					display: inline-block;
					width: 100%;
					color: #ffffff;
					text-align: center;
					line-height: 1.4;

					img {
						max-width: 90%;
					}

					h4 {
						font-size: 3.6rem;
						font-weight: 600;
					}

					.box-descr {
						position: relative;
						padding-top: 8px;
						width: 320px;
						max-width: 100%;
						margin: 0 auto;
						font-size: 1.15rem;
					}
				}
			}
		}


		@media screen and (min-width: 992px) {
			@media screen and (max-width: 1399px) {
				.col-info {
					.box {
						.inside {
							img {
								width: 265px;
							}

							h4 {
								font-size: 3.0rem;
							}

							.box-descr {
								font-size: 1.05rem;
							}
						}
					}
				}
			}

			@media screen and (max-width: 1199px) {
				.col-info {
					.box {
						.inside {
							img {
								width: 225px;
							}

							h4 {
								font-size: 2.5rem;
							}

							.box-descr {
								font-size: 0.95rem;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.col-info {
				height: inherit;
				min-height: 88vh;

				.box {
					.inside {
						.box-descr {
							width: 480px;
						}
					}
				}
			}
		}
	}

	.benefits-section {
		padding-top: 25px;
		padding-bottom: 35px;

		>.box-benefits {
			position: relative;
			display: flex;
			margin-bottom: 30px;
			flex-wrap: wrap;
			flex: 0 0 100%;
			max-width: 100%;

			.col-benefit {
				padding: 0;
				cursor: pointer;

				.name {
					margin-bottom: 4px;
					color: #b0b0b2;
					font-size: 2.3rem;
					font-weight: 400;
					text-align: center;
					@include transition(150ms);
				}

				.line {
					position: relative;
					display: inline-block;
					width: 100%;
					height: 6px;
					background-color: #d8d9da;
					@include transition(150ms);
				}

				&:first-child {
					.line {
						border-radius: 3px 0 0 3px;
					}
				}
				&:last-child {
					.line {
						border-radius: 0 3px 3px 0;
					}
				}

				&:hover {
					.name {
						color: #cea88c;
					}

					.line {
						background: #cea88c;
					}
				}

				&.active {
					.name {
						color: $orange !important;
					}

					.line {
						background-color: $orange !important;
					}
				}
			}
		}

		>.box-benefit-info {
			position: relative;
			border-radius: 25px;
			overflow: hidden;
		}

		@media screen and (max-width: 1399px) {
			>.box-benefits {
				.col-benefit {
					.name {
						margin-bottom: 2px;
						font-size: 2.0rem;
					}

					.line {
						height: 5px;
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			>.box-benefits {
				.col-benefit {
					line-height: 1.1;

					.name {
						margin-bottom: 0;
						font-size: 1.5rem;
					}

					.line {
						height: 4px;
					}
				}
			}
		}
		@media screen and (max-width: 767px) {
			padding-bottom: 10px;

			>.box-benefits {
				margin-bottom: 18px;

				.col-benefit {
					line-height: 1.0;

					.name {
						margin-bottom: 0;
						font-size: 1.05rem;
						font-weight: 500;
						line-height: 1.0;
					}

					.line {
						height: 2px;
					}
				}
			}
		}
		@media screen and (max-width: 400px) {
			>.box-benefits {
				.col-benefit {
					.name {
						font-size: 0.95rem;
					}
				}
			}
		}
	}

	.ecological-section {
		position: relative;
		padding-top: 30px;

		.col-info {
			position: relative;
			z-index: 5;

			.title {
				margin-bottom: 14px;
				font-size: 3.6rem;
				font-weight: 700;
				letter-spacing: 2px;
				line-height: 1.1;

				span {
					display: block;
				}

				.style-1 {
					color: #283a8d;
				}
				.style-2 {
					color: #00b597;
				}
			}

			.p-1 {
				margin-bottom: 16px;
			  font-size: 1.05rem;
			  font-weight: 600;
			}

			.btn-more {
				display: inline-block;
				padding: 11px 32px 12px 32px;
				color: #f26222;
				cursor: pointer;
				font-size: 1.15rem;
				font-weight: 600;
				letter-spacing: 1px;
				text-decoration: none !important;
				border: 1px solid #f26222;
				border-radius: 30px;
				transition: all 200ms ease-in-out;
				background-color: #ffffff;

				&:after {
          content: "\f061";
          position: relative;
          top: 1px;
          margin-left: 12px;
          font-family: "Font Awesome 5 Pro";
          font-size: 110%;
          font-weight: 300;
        }

				&:hover {
					color: #ffffff;
					background-color: $orange;
				}
			}
		}

		.col-image {
			text-align: right;

			img {
				position: relative;
				max-width: 100%;
				z-index: 6;
			}
		}

		&:before {
			content: " ";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 54%;
			height: 230px;
			background-position: left bottom;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url('../images/pages/home/plants.png');
			z-index: 3;
		}

		@media screen and (max-width: 991px) {
			padding-top: 10px;

			.col-info {
				text-align: center;

				.title {
					font-size: 3.3rem;
				}
			}
		}
		@media screen and (max-width: 575px) {
			.col-info {
				.title {
					margin-bottom: 6px;
					font-size: 2.8rem;
				}

				.p-1 {
					font-size: 1.0rem;
				}

				.btn-more {
					padding: 8px 32px 8px 32px;
					font-size: 0.95rem;
				}
			}
		}
	}

}
