.btn-s1 {
  display: inline-block;
  padding: 0.7rem 1.24rem;
  color: #ffffff;
  font-weight: 500;
  text-decoration: none !important;
  border: 0;
  border-radius: 25px;
  background: rgb(242,97,32);
  background: linear-gradient(90deg, rgba(242,97,32,1) 0%, rgba(247,145,40,1) 100%);

  i {
    position: relative;
    margin-right: 5px;
    top: 1px;
    font-size: 125%;
  }

  &.btn-lg {
    padding: 0.6rem 1.90rem 0.75rem 1.90rem;
    font-size: 1.15rem;

    i {
      top: 4px;
      margin-right: 9px;
      font-size: 150%;
    }
  }
}
