.banner-s2-component {
  position: relative;
  width: 100%;

  a {
    display: block;
  }

  .desktop {}

  .mobile {
    display: none;
  }

  &:before, &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    width: 50%;
    height: 42px;
    z-index: 5;
  }
  &:before {
    left: 0;
    border-radius: 0 42px 0 0;
    background-color: #283a8d;
  }
  &:after {
    right: 0;
    border-radius: 42px 0 0 0;
    background-color: #7fc5ed;
  }

  @media screen and (max-width: 991px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
  @media screen and (max-width: 767px) {
    &:before, &:after {
      height: 30px;
    }
  }
}
