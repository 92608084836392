#products-results-page{

  .products-section {
    padding-top: 35px;
    padding-bottom: 35px;

    .col-title {
      margin-bottom: 5px;
      text-align: center;
    }

    .col-products-sample-1 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

}
