#technology-page {

  .banner-section {
    position: relative;
    width: 100%;
    height: 55vh;
    background: $blue;

    .box {
      display: inline-block;
      width: 100%;
      text-align: center;

      .title-s1 {
        color: #ffffff;
      }
    }

    @media screen and (max-width: 1199px) {
      height: 44vh;
    }
    @media screen and (max-width: 575px) {
      height: 235px;
    }
  }

  .content-section {
    padding-top: 40px;
    padding-bottom: 35px;

    .col-title {
      text-align: center;

      .sec-title {
        color: $orange;
        font-size: 1.9rem;
      }
    }

    .col-tech {
      margin-top: 25px;
      margin-bottom: 25px;

      .box-tech {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .box-image {
          position: relative;
          width: 270px;
          max-width: 40%;
          border: 3px solid #4997d3;
          border-radius: 50%;

          &:before {
            content: " ";
            position: absolute;
            bottom: -3px;
            left: 50%;
            width: 200%;
            border-top: 3px solid #4997d3;
          }

          .empty {
            width: 100%;
          }

          .inside {
            position: absolute;
            padding: 15px;
            top: 12px;
            left: 12px;
            width: calc(100% - 24px);
            height: calc(100% - 24px);
            overflow: hidden;
            border-radius: 50%;
            background-color: #f79228;

            &:after {
              content: " ";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 50%;
              background-color: #f26222;
            }

            .img {
              position: relative;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: $soft-gray;
              z-index: 5;
            }
          }
        }

        .box-info {
          padding-left: 35px;
          max-width: calc(100% - 270px);

          .title {
            color: $blue;
            font-size: 3.3rem;
            font-weight: 700;
          }

          .descr {
            color: #353841;
            font-size: 1.25rem;
            font-weight: 600;
          }
        }
      }

      &.r {
        .box-tech {
          justify-content: end;

          .box-image {
            order: 1;

            &:before {
              left: inherit;
              right: 50%;
            }
          }

          .box-info {
            order: 0;
            padding-left: 0;
            padding-right: 35px;
            text-align: right;
          }
        }
      }
    }

    @media screen and (max-width: 1199px) {
      .col-title {
        .sec-title {
          font-size: 1.55rem;
        }
      }

      .col-tech {
        .box-tech {
          .box-image {
            width: 250px;
          }

          .box-info {
            max-width: calc(100% - 250px);

            .title {
              font-size: 2.3rem;
            }

            .descr {
              font-size: 1.15rem;
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 22px;
      padding-bottom: 22px;

      .col-title {
        .sec-title {
          font-size: 1.35rem;
        }
      }

      .col-tech {
        .box-tech {
          .box-image {
            width: 140px;
            border-width: 2px;

            &:before {
              border-top-width: 2px;
              bottom: -2px;
            }

            .inside {
              padding: 8px;
              top: 6px;
              left: 6px;
              width: calc(100% - 12px);
              height: calc(100% - 12px);
            }
          }

          .box-info {
            max-width: calc(100% - 140px);
            padding-left: 15px;

            .title {
              font-size: 1.5rem;
            }

            .descr {
              font-size: 0.95rem;
            }
          }
        }

        &.r {
          .box-tech {
            .box-info {
              padding-right: 15px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px) {
      .col-title {
        .sec-title {
          font-size: 1.20rem;
        }
      }

      .col-tech {
        .box-tech {
          .box-image {
            width: 120px;

            .inside {
              padding: 5px;
              top: 4px;
              left: 4px;
              width: calc(100% - 8px);
              height: calc(100% - 8px);
            }
          }

          .box-info {
            max-width: calc(100% - 120px);
            padding-left: 10px;

            .title {
              margin-bottom: 2px;
              font-size: 1.30rem;
              line-height: 1.1;
            }

            .descr {
              font-size: 0.90rem;
              line-height: 1.2;
            }
          }
        }

        &.r {
          .box-tech {
            .box-info {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }

  .banner-s2-component {
    margin-top: 12px;
  }

}
