#header {
	position: relative;

	.header-content {
		position: relative;

		.navbar {
			padding-top: 0.7rem;
			padding-bottom: 0.7rem;

			&.bg-light {
				background-color: #f4f5f5 !important;
			}

			.navbar-brand {
				img {
					height: 95px;
				}
			}

			.navbar-toggler {
				color: $blue;
				font-size: 2.1rem;
				border: 0;
			}

			#nav-collapse {
				.navbar-nav {
					.simple-item {
						position: relative;

						.nav-link {
							color: #33363f;
							font-size: 1.05rem;
							font-weight: 600;
							@include transition(150ms);

							&:hover {
								color: $orange;
							}
						}
					}

					.dropdown {
						.dropdown-toggle {
							color: $blue;
						}

						.dropdown-menu {
							border-radius: 3px;

							li {
								.dropdown-item {

									&:before {
										display: none !important;
									}

									&:hover, &:focus, &:active {
										color: #ffffff;
										background-color: $orange;
									}
								}
							}
						}
					}

					.cart {
						.nav-link {
							color: $blue !important;

							&:before {
								display: none !important;
							}

							.icon {
								position: relative;
								position: relative;
								top: 2px;

								.fa-shopping-cart {
									font-size: 130%;
								}

								.num {
									position: absolute;
									padding-top: 2px;
									top: -9px;
									right: -8px;
									width: 19px;
									height: 19px;
									color: #ffffff;
									font-size: 0.7rem;
									font-style: normal;
									text-align: center;
									border-radius: 50%;
									background-color: $orange;
									overflow: hidden;
								}
							}
						}
					}

					.nav-phone {
						.nav-link {
							position: relative;
							padding-left: 33px;
							color: $blue;
							line-height: 1.2;

							span {
								display: block;
								font-size: 0.90rem;
							}

							strong {
								font-size: 1.15rem;
								text-decoration: underline;
							}

							&:before {
								content: "\f3cf";
								position: absolute;
								top: 6px;
								left: 0;
								color: $orange;
								font-family: "Font Awesome 5 Pro";
								font-size: 2.2rem;
								font-weight: 300;
							}
						}
					}

					.nav-order {
						.nav-link {
							padding: 0.7rem 1.24rem;
							color: #ffffff;
							font-size: 0.90rem;
							font-weight: 600;
							border-radius: 20px;
							background: rgb(242,97,32);
							background: linear-gradient(90deg, rgba(242,97,32,1) 0%, rgba(247,145,40,1) 100%);

							i {
								margin-right: 5px;
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			.navbar {
				#nav-collapse {
					.navbar-nav {
						.simple-item {
							margin-left: 0.4vw;
							margin-right: 0.4vw;

							.router-link-exact-active {
								&:before {
									content: " ";
									position: absolute;
									bottom: -6px;
									left: 0;
									width: 100%;
									border-top: 4px solid $orange;
								}
							}
						}

						.nav-phone {
							margin-top: -6px;

							.nav-link {
								padding-left: 33px;
								margin-right: 14px;
							}
						}
					}
				}
			}

			@media screen and (max-width: 1399px) {
				.navbar {
					.navbar-brand {
						img {
							height: 75px;
						}
					}

					#nav-collapse {
						.navbar-nav {
							.simple-item {
								.nav-link {
									font-size: 1.0rem;
								}
							}
						}
					}
				}
			}

			@media screen and (max-width: 1299px) {
				.navbar {
					#nav-collapse {
						.navbar-nav {
							.simple-item {
								margin-left: 0;
								margin-right: 0;

								.nav-link {
									padding-right: 0.4rem;
									padding-left: 0.4rem;
								}
							}
						}
					}
				}
			}

			@media screen and (max-width: 1199px) {
				.navbar {
					padding-top: 0.45rem;
					padding-bottom: 0.45rem;

					.navbar-brand {
						img {
							height: 62px;
						}
					}

					#nav-collapse {
						.navbar-nav {
							.simple-item {
								margin-left: 0;
								margin-right: 0;

								.nav-link {
									font-size: 0.9rem;
								}
							}

							.nav-order {
								display: none;
							}

							.nav-phone {
								.nav-link {
									margin-right: 6px;
									padding-left: 25px;

									&:before {
										top: 8px;
										font-size: 1.8rem;
									}

									span {
										font-size: 0.75rem;
									}

									strong {
										font-size: 0.95rem;
									}
								}
							}

							.nav-order {
								.nav-link {
									padding: 0.6rem 0.9rem;
									font-size: 0.75rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.navbar {
				padding-top: 0.3rem;
				padding-bottom: 0.3rem;

				.navbar-brand {
					img {
						height: 45px;
					}
				}

				#nav-collapse {
					.simple-item {
						.nav-link {
							padding-top: 0.2rem;
							padding-bottom: 0.2rem;
						}

						.router-link-exact-active {
							color: $orange !important;
						}
					}
				}
			}
		}
	}

}
