#contact-page {

  .contact-form {
    padding-top: 35px;
    padding-bottom: 35px;

    .col-title {
      margin-bottom: 15px;
      text-align: center;
    }
  }

  .banner-s2-component {
    margin-top: 5px;
  }

}
