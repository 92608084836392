#order-page {

  .contact-form {
    padding-top: 35px;
    padding-bottom: 35px;

    .col-title {
      margin-bottom: 20px;
      text-align: center;
    }

    .col-image {
      text-align: center;

      img {
        max-width: 90%;
      }
    }

    .col-form {
      .note {
        color: $blue;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.1;
      }

      .text-notas {
        @media screen and (min-width: 992px) {
          min-height: 294px;
        }
      }
    }
  }

  .banner-s2-component {
    margin-top: 5px;
  }

}
