.title-s1 {
  position: relative;
  color: $blue;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.1;

  span {
    position: relative;
    display: inline-block;
    padding-bottom: 6px;

    &:before {
      content: " ";
      position: absolute;
      bottom: 0;
      left: -10%;
      width: 42%;
      height: 5px;
      border-radius: 4px;
      background-color: #fac52c;
    }
  }

  @media screen and (max-width: 575px) {
    font-size: 2.7rem;
  }
}

.title-s2 {
  color: #000 !important;
  font-size: 1.60rem;
  font-weight: 700 !important;
  margin-bottom: 2px;
}
