#footer {
  position: relative;
  background: #e7e6e6;

  .row-n1 {
    padding-top: 25px;
    padding-bottom: 15px;
  }

  .row-n2 {
    padding-bottom: 20px;
  }

  .line {
    margin-top: 0;
    margin-bottom: 0.8rem;
    border-top-color: $orange;
  }

  .col-footer {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #3a3d46;

    .btn-logo {
      display: inline-block;

      img {
        max-width: 100%;
        width: 230px;
      }
    }

    .title {
      margin-bottom: 10px;
      color: $blue;
      font-size: 1.55rem;
      font-weight: 700;
    }

    .link-s1 {
      position: relative;
      display: inline-block;
      color: #3a3d46;
      text-decoration: none !important;
      font-weight: 600;

      &:hover {
        color: $orange;
      }

      &.router-link-exact-active {
        color: #3a3d46 !important;

        &:after {
          content: " ";
          position: absolute;
          bottom: -2px;
          left: -5px;
          width: calc(100% + 10px);
          border-top: 2px solid $orange;
        }
      }
    }

    .link-s2 {
      display: inline-block;
      color: $blue;
      font-size: 1.05rem;
      font-weight: 600;
      text-decoration: underline;
    }

    .btn-network {
      @include flex-center-xy();
      margin: 1px 10px;
      flex: 0 0 48px;
      height: 48px;
      color: #ffffff;
      font-size: 1.8rem;
      line-height: 1;
      text-align: center;
      text-decoration: none !important;
      vertical-align: middle;
      border-radius: 50%;
      background-color: $blue;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: $orange;
      }
    }

    .btn-network-s2 {
      display: inline-block;
      color: $blue;
      font-size: 3.15rem;
      line-height: 1;
      margin-right: 10px;

      &:hover {
        color: $orange;
      }
    }

    .btn-call {
      position: relative;
      display: inline-block;
      padding-left: 39px;
      color: $blue;
      line-height: 1.3;
      text-align: left;
      text-decoration: none !important;

      span {
        display: block;
        font-size: 1.30rem;
      }

      strong {
        font-size: 1.55rem;
        text-decoration: underline;
      }

      &:before {
        content: "\f3cf";
        position: absolute;
        top: 1px;
        left: 0;
        color: $orange;
        font-family: "Font Awesome 5 Pro";
        font-size: 2.8rem;
        font-weight: 300;
      }
    }

    .btn-order {
      display: inline-block;
      padding: 0.7rem 1.24rem;
      color: #ffffff;
      font-size: 1.15rem;
      font-weight: 500;
      text-decoration: none !important;
      border-radius: 25px;
      background: rgb(242,97,32);
      background: linear-gradient(90deg, rgba(242,97,32,1) 0%, rgba(247,145,40,1) 100%);

      i {
        position: relative;
        margin-right: 5px;
        top: 1px;
        font-size: 125%;
      }
    }

    .copy {
      color: $blue;
      text-align: right;

      strong {
        font-weight: 600;
      }
    }
  }

  @media screen and (min-width: 992px) {
    @media screen and (max-width: 1399px) {
      .col-footer {
        .btn-logo {
          img {
            width: 210px;
          }
        }

        .txt-info {
          font-size: 0.90rem;
        }

        .title {
          font-size: 1.35rem;
        }

        .btn-network {
          margin: 1px 7px;
          flex: 0 0 44px;
          height: 44px;
          font-size: 1.5rem;
        }

        .btn-network-s2 {
          margin-right: 7px;
          font-size: 2.80rem;
        }
      }
    }
    @media screen and (max-width: 1199px) {
      .col-footer {
        .btn-call {
          padding-left: 31px;

          &:before {
            font-size: 2.3rem;
          }

          span {
            font-size: 1.00rem;
          }

          strong {
            font-size: 1.30rem;
          }
        }

        .btn-order {
          font-size: 0.95rem;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .row-n1 {
      padding-top: 15px;
    }

    .row-n2 {
      padding-bottom: 8px;
    }

    .col-footer {
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    .row-n2 {
      .col-footer {
        padding-top: 3px;
        padding-bottom: 3px;

        .copy {
          font-size: 0.95rem;
          line-height: 1.2;
          text-align: center;

          strong {
            display: block;
          }
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    font-size: 0.95rem;
    line-height: 1.4;

    .col-footer {
      .title {
        margin-bottom: 5px;
        font-size: 1.35rem;
      }

      .btn-network {
        flex: 0 0 44px;
        height: 44px;
        font-size: 1.5rem;
      }

      .btn-network-s2 {
        font-size: 2.75rem;
      }

      .btn-call {
        &:before {
          font-size: 2.5rem;
        }

        span {
          font-size: 1.15rem;
        }

        strong {
          font-size: 1.4rem;
        }
      }

      .btn-order {
        font-size: 1.0rem;
      }
    }
  }
}
