.breadcrumb-section{
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  color: #97989b;
  font-size: 0.85rem;

  span{
    display: inline-block;
  }

  .line{
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
  }

  a{
    color: #97989b;
  }
}
