.form-group-s1 {
  margin-bottom: 1.15rem;
  label, legend {
    padding-bottom: 4px;
    color: #003f85;
    font-size: 1.15rem;
    font-weight: 600;
  }

  >div {
    .form-control {
      padding: 0.7rem 1.35rem;
      height: inherit;
      resize: none;
      border: 1px solid #d1d2d3;
      border-radius: 0;
      background-color: #f4f7f6 !important;
    }
  }
}
