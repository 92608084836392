.col-products-sample-1 {
  .box-link {
    position: relative;
    display: inline-block;
    width: 100%;
    color: #3a3d46;
    text-decoration: none !important;

    .box-image {
      display: block;
      margin-bottom: 5px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include transition(200ms);

      img {
        width: 100%;
      }
    }

    .box-info {
      display: block;

      .name {
        margin-bottom: 10px;
        color: $blue;
        font-size: 1.7rem;
        font-weight: 700;
        text-align: center;
      }

      .descr {
        display: block;
        margin-bottom: 15px;
        min-height: 62px;
        font-size: 1.0rem;
        line-height: 1.3;
        text-align: center;
      }

      .btn-order {
        display: inline-block;
        padding: 9px 20px;
        width: 76%;
        color: $orange;
        cursor: pointer;
        font-size: 1.10rem;
        font-weight: 600;
        letter-spacing: 1px;
        text-decoration: none !important;
        border: 1px solid $orange;
        border-radius: 22px;
        @include transition(200ms);

        &:after {
          content: "\f061";
          position: relative;
          top: 1px;
          margin-left: 12px;
          font-family: "Font Awesome 5 Pro";
          font-size: 110%;
          font-weight: 300;
        }
      }
    }

    &:hover {
      .box-image {
        opacity: 0.90;
      }

      .box-info {
        .btn-order {
          color: #ffffff;
          background-color: $orange;
        }
      }
    }
  }

  @media screen and (max-width: 1399px) {
    .box-link {
      .box-info {
        .name {
          margin-bottom: 5px;
          font-size: 1.40rem;
          line-height: 1.1;
        }

        .descr {
          position: relative;
          margin-bottom: 7px;
          height: 55px;
          font-size: 0.95rem;
          line-height: 1.2;
          overflow: hidden;
        }

        .btn-order {
          padding: 6px 20px 8px 20px;
          font-size: 0.95rem;

          &:after {
            font-size: 105%;
          }
        }
      }
    }
  }
}
